import React from 'react';
import '../styles/Contact.css';  // Updated import path

const Contact = () => {
    return (
        <div className="contact">
            <div className="contact-row">
                <div className="contact-item">
                    <h2>Kontakt</h2>
                    <p>E-Mail: <a href="mailto:info@backshop.com">info@backshop.com</a></p>
                    <p>Telefon: <a href="tel:+4915510939700">+49 15510 939700</a></p>
                    <p>
                        Adresse:
                        <a
                            href="https://www.google.com/maps?q=Landsberger+Allee+131,+10369+Berlin,+Germany"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Landsberger Allee 131, 10369 Berlin, Germany
                        </a>
                    </p>
                </div>

                <div className="contact-item">
                    <h3>Öffnungszeiten</h3>
                    <p>Montag - Freitag: 07:00 - 18:00</p>
                    <p>Samsatag: 08:00 - 17:00</p>
                    <p>Sonntag: 08:00 - 18:00</p>
                </div>

                <div className="contact-item">
                    <h3>Online bestellen</h3>
                    <p>Auf diesen Plattformen können Sie unsere leckeren Backwaren bestellen:</p>
                    <p>Klicken Sie auf den Link unten, um jetzt zu bestellen!:</p>

                    <ul className="order-links">
                        <li><a href="https://wolt.com/en/deu/berlin/restaurant/backshop-by-hamo?srsltid=AfmBOorc-I_nXl_kscHc5HCyKoEo8Cvpbqwq4ViYKSJsDFi_VOIZNPHs" target="_blank" rel="noopener noreferrer">Wolt</a></li>
                        <li><a href="https://www.lieferando.de/da/menu/backshop-by-hamo" target="_blank" rel="noopener noreferrer">Lieferando</a></li>
                        <li><a href="https://www.ubereats.com/de/store/backshop-by-hamo/SgZVP_kbUGmhkOu6pKh29w?srsltid=AfmBOoqNBbVzKyhBiSEueVnMAelLuKUK-2XN_vOyKMx7xEnWHqOMAYLQ" target="_blank" rel="noopener noreferrer">Uber Eats</a></li>
                    </ul>
                </div>
            </div>

            <div className="contact-item pickup-return">
                <h3>Paketabholung und -rückgabe</h3>
                <p>Sie können Pakete auch bei uns abholen oder über unsere Partner zurücksenden:</p>
                <ul className="pickup-links">
                    <p>Hermes PaketShop</p>
                </ul>
            </div>
        </div>
    );
};

export default Contact;
