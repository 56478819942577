// src/components/Home.js
import React from 'react';
import '../styles/Home.css';

const Home = () => {
    return (
        <div className="home">
            <div className="hero">
                <img src="/images/her2.jpg" alt="Fresh bakery" />
                <div className="hero-text">
                    <h2>Willkommen bei Backshop By Hamo</h2>
                    <p>Genießen Sie unsere frischen, mit Liebe hergestellten Backwaren</p>
                    {/* <p className="holiday-notice">Liebe Kunden, wir machen in den Kalenderwochen 30 und 32 Urlaub</p> */}
                </div>

            </div>
        </div>
    );
};

export default Home;
