// src/components/Navbar.js
import React from 'react';
import { Link } from 'react-router-dom';
import '../styles/Navbar.css';

const Navbar = () => {
    return (
        <nav className="navbar">
            {/* Use an image for the logo */}
            <Link to="/" className="logo">
                <img src="/images/logo.jpg" alt="Backshop Logo" className="logo-image" />
            </Link>
            <ul>
                <li><Link to="/">Startseite</Link></li>
                <li><Link to="/menu">Menü</Link></li>
                <li><Link to="/contact">Information</Link></li>
            </ul>
        </nav>
    );
};

export default Navbar;